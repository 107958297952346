import LoadingComponent from 'componentsTemplate/Loading';
import loadable from 'react-loadable';

export const PAYMENT_FORM_IFRAME_ROUTE = '/iframe/payment-form'
let PaymentForm = loadable({
  loader: () => import('componentsConcrete/stripePaymentForm'),
  loading: LoadingComponent
})

export const SIDEBAR_INQUIRY_IFRAME_ROUTE = '/iframe/sidebar-inquiry'
let SidebarInquiry = loadable({
  loader: () => import('componentsConcrete/sidebarInquiry'),
  loading: LoadingComponent
})

const iframeRoutes = [
  { path: PAYMENT_FORM_IFRAME_ROUTE, exact: true, name: 'Payment Form', component: PaymentForm},
  { path: SIDEBAR_INQUIRY_IFRAME_ROUTE, exact: true, name: 'Inquiry', component: SidebarInquiry},
];

export default iframeRoutes;
