import LoadingComponent from 'componentsTemplate/Loading';
import loadable from 'react-loadable';

export const LOGIN_PAGE_ROUTE = "/host/login"
let Login = loadable({
  loader: () => import('pagesPublic/Login/'),
  loading: LoadingComponent
})

export const ABOUT_PAGE_ROUTE = "/host/about"


const publicRoutes = [
  { path: LOGIN_PAGE_ROUTE, exact: true, name: 'Login', component: Login},
];

export default publicRoutes;
