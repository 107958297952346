import DefaultReduxToastr from "@bit/samsteady.react-generic.utils.default-redux-toastr";
import ScrollToTop from 'componentsTemplate/ScrollToTop';
import {ConnectedRouter} from "connected-react-router";
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Provider} from 'react-redux';
import {Route} from 'react-router-dom';
import App from './App';

export default class Root extends Component {
  render() {
    const { store, history } = this.props;
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ScrollToTop>
            <Route path="/" component={App} />
          </ScrollToTop>
        </ConnectedRouter>
        <DefaultReduxToastr/>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
