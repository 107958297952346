import {CLEAR_DATA} from "@bit/samsteady.react-generic.redux.redux-api-auth/actions";
import reduxApiAuth from "@bit/samsteady.react-generic.redux.redux-api-auth";
import reduxApi from "@bit/samsteady.react-generic.redux.redux-api";
import genericState from "@bit/samsteady.react-generic.redux.redux-generic-state";
import {composeWithDevTools} from "redux-devtools-extension";

import getRequestService from "@bit/samsteady.react-generic.redux.redux-api/middlewares/getRequestService";
import apiService from "@bit/samsteady.react-generic.redux.redux-api/middlewares/apiService";
import {connectRouter, routerMiddleware} from "connected-react-router";

import {createBrowserHistory} from "history";
import {reducer as toastrReducer} from "react-redux-toastr";
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';

import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import * as reducers from "state/ducks";

// import {routerMiddleware} from 'react-router-redux';

export const history = createBrowserHistory()

const appReducer = combineReducers({
  ...reducers,
  reduxApi,
  genericState,
  auth: reduxApiAuth,
  router: connectRouter(history),
  toastr: toastrReducer
})

const rootReducer = (state, action) => {
  if (action.type === CLEAR_DATA) {
    state = undefined
  }
  return appReducer(state, action)
}

function configureStoreProd(initialState) {
  const middlewares = [
    // Add other middleware on this line...
    routerMiddleware(history),
    getRequestService,
    apiService,

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    thunk,
  ];

  return createStore(rootReducer, initialState, compose(
    applyMiddleware(...middlewares)
    )
  );
}

function configureStoreDev(initialState) {
  const middlewares = [
    // Add other middleware on this line...
    routerMiddleware(history),
    getRequestService,
    apiService,

    // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
    reduxImmutableStateInvariant(),

    // thunk middleware can also accept an extra argument to be passed to each thunk action
    // https://github.com/gaearon/redux-thunk#injecting-a-custom-argument
    thunk,
  ];

  // noinspection JSUnresolvedVariable
  const composeEnhancers = composeWithDevTools({trace: true}) || compose; // add support for Redux dev tools
  const store = createStore(rootReducer, initialState, composeEnhancers(
    applyMiddleware(...middlewares)
    )
  );

  return store;
}

// noinspection JSUnresolvedVariable,ES6ModulesDependencies
const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev;

export default configureStore;
