import LoadingComponent from 'componentsTemplate/Loading';
import loadable from 'react-loadable';

export const DASHBOARD_PAGE_ROUTE = "/host/dashboard"
let Dashboard = loadable({
  loader: () => import('pagesPrivate/Dashboard'),
  loading: LoadingComponent
})

export const LOGOUT_PAGE_ROUTE = "/host/logout"
let Logout = loadable({
  loader: () => import('pagesPrivate/Logout'),
  loading: LoadingComponent
})

export const HOST_DATA_PAGE_ROUTE = "/host/data"
let HostData = loadable({
  loader: () => import('pagesPrivate/HostData'),
  loading: LoadingComponent
})

const privateRoutes = [
  { path: DASHBOARD_PAGE_ROUTE, exact: true, name: 'Dashboard', component: Dashboard},
  { path: LOGOUT_PAGE_ROUTE, exact: true, name: 'Logout', component: Logout},
  { path: HOST_DATA_PAGE_ROUTE, exact: true, name: 'Host Data', component: HostData},
];

export default privateRoutes;
