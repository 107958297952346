import FetchCurrentUser from '@bit/samsteady.react-generic.auth.fetch-current-user';
import { loggedIn } from '@bit/samsteady.react-generic.redux.token-manager/tokenManager';
import ExternalRedirectRoute from '@bit/samsteady.react-generic.routing.external-redirect-route';
import { spreadRoutes, spreadRoutesWithLayout } from '@bit/samsteady.react-generic.utils.generic-react-utils';
import LoadingComponent from 'componentsTemplate/Loading';
import React from 'react';
import loadable from 'react-loadable';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { Redirect, Switch } from 'react-router-dom';
import iframeRoutes from 'routes/iframeRoutes';
import privateRoutes from 'routes/privateRoutes';
import publicRoutes from 'routes/publicRoutes';
// 3rd
import 'styles/antd.less';
import 'styles/bootstrap/bootstrap.scss';
// custom
import 'styles/layout.scss';
import 'styles/theme.scss';
import 'styles/ui.scss';
import 'styles/vendors.scss';


let AppLayout = loadable({
  loader: () => import('componentsTemplate/Layout/AppLayout/'),
  loading: LoadingComponent
})

let PublicPagesLayout = loadable({
  loader: () => import('componentsTemplate/Layout/PublicPagesLayout/'),
  loading: LoadingComponent
})


function App(props) {

  return (
    <div id="app">
        {loggedIn()?
          <div>
            <FetchCurrentUser/>
            <Switch>
              {spreadRoutesWithLayout(privateRoutes, AppLayout)}
              {spreadRoutes(iframeRoutes)}
              <Redirect from="/host" to="/host/dashboard"/>
              <ExternalRedirectRoute
                internalPathRegexPrefix="host"
              />
            </Switch>
          </div>
          :
          <Switch>
            {spreadRoutesWithLayout(publicRoutes, PublicPagesLayout)}
            {spreadRoutes(iframeRoutes)}
            <Redirect from="/host" to="/host/login"/>
            <ExternalRedirectRoute
              internalPathRegexPrefix="host"
            />
          </Switch>
        }
    </div>
  );
}


//TODO: do i need a withRouter here?
export default App
